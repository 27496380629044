<!--  -->
<template>
  <div class="questionnaire_result">
    <div class="main" v-if="submitState == 0">
      <!-- 标题 -->
      <div class="head">
        <div class="tit">教学满意度调查问卷</div>
        <!-- <div class="time">2021年10月11日 10:59</div> -->
      </div>
      <!-- 表单列表 -->
      <div class="list">
        <form
          action=""
          name="formName"
          method="post"
          class="group"
          id="formName"
          v-model="subjectList"
        >
          <div
            class="list2"
            v-for="(item, index) in subjectList"
            :key="item.id"
            :style="{ display: item.dispaly }"
          >
            <div class="name_group">
              <div class="subject_tit">{{ item.subject }}</div>
              <div class="subject_type">{{ computed_state(item.type) }}</div>
              <span
                style="color:red"
                v-if="item.Mandatory || item.identification"
                >必填</span
              >
            </div>
            <div
              class="options"
              v-for="(item2, index2) in item.optionList"
              :key="index2"
            >
              <input
                :name="item.id"
                :type="item.type"
                size="22"
                @click="toInput(item, item2, index2, index)"
                placeholder="请填写"
                v-model="item.model_value"
                :class="{ options_input: item.type === 'input' }"
              />
              <label>{{ item2.value }}</label>
            </div>
          </div>
          <input type="button" class="button" value="提交" @click="run()" />
        </form>
      </div>
    </div>
    <!-- 登录界面（用户没有登陆时候到这页面弹出） -->
    <div class="login" v-if="!loginState">
      <div class="Mask">
        <el-form
          ref="loginForm"
          :model="form"
          :rules="rules"
          label-width="80px"
          class="login-box"
        >
          <el-form-item>
            <h3 class="login-title">欢迎登录</h3>
          </el-form-item>
          <el-form-item label="账号：" prop="username">
            <el-input
              type="text"
              placeholder="请输入账号"
              v-model="form.username"
              oninput="this.value=this.value.replace(/\D/g, '')"
            />
          </el-form-item>
          <el-form-item label="密码：" prop="password">
            <el-input
              type="password"
              placeholder="请输入密码"
              v-model="form.password"
              @keypress.native.enter="handleLogin"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              class="register-btn"
              type="primary"
              v-on:click="handleLogin"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div
      v-else
      style="display: flex;
    justify-content: center;"
    >
      <h1 style="font-size: 50px;padding-top: 50px;">提交成功</h1>
    </div>
  </div>
</template>
<script>
import {
  getansTitleList,
  subAnsSave,
  userPcLogin,
  userSiteLogin
} from "@/api/questionnaire";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { login } from "@/api/api";
import store from "@/store";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    var validatorPhone = function(rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    //这里存放数据
    return {
      submitState: 0, //提交状态
      subjectList: [], //题目集合
      ans: [{}], //账号作答集合
      loginState: false, //登录状态
      start_time: "", //开始时间
      end_tiem: "", //结束时间
      token: "",
      nowIndex: "", //当前选中的下标
      form: {
        username: "",
        password: "",
        againPassword: "",
        url: ""
        // code: "",
        // key: ""
      },
      // 表单验证，需要在 el-form-item 元素中增加 prop 属性
      rules: {
        username: [
          { required: true, message: "账号不可为空", trigger: "change" },
          { validator: validatorPhone, trigger: "change" }
        ],
        password: [
          { required: true, message: "密码不可为空", trigger: "change" },
          {
            min: 5,
            max: 30,
            message: "密码长度在 5 到 30 个字符",
            trigger: "change"
          }
        ]
      }
    };
  },
  //监听属性 类似于data概念
  computed: {
    //做数据过滤  题目类型
    computed_state() {
      return e => {
        if (e === "radio") {
          return "【单选题】";
        } else if (e === "checkbox") {
          return "【多选题】";
        } else if (e === "input") {
          return "【填空题】";
        }
      };
    },
    tokenState() {
      console.log("token", this.token);
      return this.token;
    }
  },
  //监控data中的数据变化
  watch: {
    subjectList: {
      handler(val) {
        console.log("subjectList", val);
      },
      deep: true
    },
    ans: {
      handler(val) {
        console.log("ans", val);
      },
      deep: true
    },
    token(val) {
      console.log(val);
    }
  },
  //方法集合
  methods: {
    //登录方法
    handleLogin() {
      var data = {
        area_code: "+86",
        phone: this.form.username,
        password: this.form.password
      };
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          userSiteLogin(data).then(res => {
            this.$message.success("登录成功");
            this.token = getToken();
            if (res.code !== 1) {
              this.$message.error(res.code);
              return;
            }
            setToken(res.access_token);
            store.commit("setToken", res.access_token);
            // this.$router.push("Main");
          });
          this.loginState = true;
        } else {
          this.$message.error("请正确填写用户名及密码!");
          return false;
        }
      });
    },
    //答案选项点击事件
    toInput(item, item2, index, num) {
      console.log(item, item2, index, num);
      //更新下标
      this.nowIndex = num;
      if (item.type === "radio") {
        item.optionList.forEach(val => {
          val.select = false;
        });
        item2.select = true;
        console.log(item.special, item2.key);
        //判断单选框是否选了不满意
        if (item.special == item2.key) {
          this.subjectList[num + 1].dispaly = "block";
        } else {
          this.subjectList[num + 1].dispaly = "none";
        }
      }
      if (item.type === "checkbox") {
        item2.select = !item2.select;
      }
    },
    //必填项未填提示
    open4(text) {
      this.$message.error("哈哈哈" + text);
    },
    //提交按钮
    run() {
      if (!this.token) {
        alert("请登录账户");
        return "";
      } else {
        this.end_tiem = this.getNowTime();
        //给答案数据赋值过滤后的问题数组
        try {
          this.subjectList.forEach((res, rindex) => {
            if (res.Mandatory) {
              if (res.type == "input") {
                if (!res.model_value.trim()) {
                  console.log(res.model_value);
                  // alert("请完善问卷" + "(" + res.subject + ")");
                  this.open4("请完善问卷" + "(" + res.subject + ")");
                  throw Error("请完善问卷");
                  // return false;
                }
              } else if (res.type == "radio" || res.type == "checkbox") {
                console.log("res.optionList", res.optionList);
                console.log(
                  "res.optionList.filter(item => item.select)",
                  res.optionList.filter(item => item.select).length
                );
                let result = res.optionList.filter(item => item.select);
                console.log(!!result);
                if (!result.length) {
                  // alert("请完善问卷" + "(" + res.subject + ")");
                  this.open4("请完善问卷" + "(" + res.subject + ")");
                  throw Error("请完善问卷");
                  return false;
                }
              }
            }
            this.ans[rindex].title_id = res.id;
            if (res.identification) {
              this.ans[rindex - 1].specail_answer = res.model_value;
            }
            this.ans[rindex].answerList = res.optionList.filter(res2 => {
              return res2.select;
            });
          });
        } catch (e) {
          console.log(e, "错误");
        }
        //把answer里的数据变成A,B格式
        this.ans.forEach(item => {
          item.answer = "";
          item.answerList.forEach(item2 => {
            console.log("====", item, item2);
            item.answer += item2.key + ",";
          });
        });
        //改变ans数组格式
        this.ans = this.ans.map((res, index) => {
          return {
            title_id: res.title_id,
            answer: res.answer
              ? res.answer
              : this.subjectList[index].model_value,
            specail_answer: res.specail_answer ? res.specail_answer : ""
          };
        });
        this.ans = this.ans.filter(item => item.title_id);
        console.log("this.ans", this.ans);
        subAnsSave({
          theme_id: 1,
          start_time: this.start_time,
          end_time: this.end_tiem,
          ans: this.ans
        })
          .then(res => {
            console.log(res);
            this.submitState = 1;
          })
          .catch(info => {
            console.log(info);
          });
      }
    },
    //问卷答题列表
    getgetansData(id) {
      //问卷题目列表
      getansTitleList({ theme_id: id }).then(res => {
        this.subjectList = res.data.data.map((val, index) => {
          if (val.options) val.options = JSON.parse(val.options);
          val.optionList = [];
          val.model_value = "";
          // val.model_value = " ";
          //把答案对象集合拿出来做一个数组对象方便查看选中状态
          for (let key in val.options) {
            let num = val.options[key];
            let keyName = key;
            val.optionList.push({ key: key, value: num, select: false });
          }
          if (val.type == 3 || val.type == 4) {
            if (val.type == 4) {
              val.Mandatory = 1;
            } else if (val.type == 3) {
              val.Mandatory = 0;
            }
            val.type = "radio";
          }
          if (val.type == 5 || val.type == 6) {
            if (val.type == 6) {
              val.Mandatory = 1;
            } else if (val.type == 5) {
              val.Mandatory = 0;
            }
            val.type = "checkbox";
          }
          if (val.type == 1 || val.type == 2) {
            if (val.type == 2) {
              val.Mandatory = 1;
            } else if (val.type == 1) {
              val.Mandatory = 0;
            }
            val.type = "input";
          }
          console.log(val, "val");

          return val;
        });
        //往数据源里添加为什么不满意对象数据
        this.subjectList.forEach((res, index) => {
          if (res.type === "radio") {
            console.log("radiop", index);
            this.subjectList.splice(index + 1, 0, {
              subject: `为什么${res.subject}不满意`,
              type: "input",
              identification: 1, //自动生成的标识
              // id: Math.random(),
              optionList: [{}],
              dispaly: "none",
              model_value: ""
            });
          }
        });

        console.log("this.subjectList", this.subjectList);
        //循环出答案空数组防止添加数据时报错
        for (let i = 0; i < this.subjectList.length - 1; i++) {
          this.ans.push({});
        }
      });
    },
    //获取当前时间
    getNowTime() {
      let time = new Date();
      return (
        time.getFullYear() +
        "-" +
        (time.getMonth() + 1) +
        "-" +
        time.getDate() +
        " " +
        time.getHours() +
        ":" +
        time.getMinutes() +
        ":" +
        time.getSeconds()
      );
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getgetansData();
    this.token = getToken();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.start_time = this.getNowTime();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
.questionnaire_result {
  background-color: #f5f5f5;
  overflow: auto;
  height: 100vh;
  position: relative;
  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 900px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .head {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .tit {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 26px;
        color: #323232;
        opacity: 1;
      }
      .time {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 26px;
        color: #323232;
        opacity: 1;
        margin-top: 8px;
      }
    }
    .list {
      margin-top: 40px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 0 70px 70px;
      .group {
        .list2 {
          padding: 30px 0 0 0;
        }
        .options {
          display: flex;
          align-items: center;
          margin: 10px 10px 0 0;
          label {
            margin-left: 4px;
          }
          .options_input {
            width: 500px;
            height: 40px;
            background: #ffffff;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 26px;
            border: 1px solid #dcdfe6;
            opacity: 1;
            border-radius: 5px;
            padding-left: 20px;
          }
        }
      }

      .content {
        overflow-y: hidden; //去掉滚动条
        padding: 0; //去除内边距
      }
      .name_group {
        display: flex;
        align-items: center;
        .subject_tit {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 26px;
          color: #323232;
          opacity: 1;
        }
        .subject_type {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 26px;
          color: #969696;
        }
      }
    }
  }
  .button {
    width: 130px;
    height: 30px;
    background: #37a58c;
    border-radius: 3px;
    font-size: 12px;
    border: 0;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
    opacity: 1;
    line-height: 5px;
    display: block;
    margin: 0 auto;
    transform: translate(0, 30px);
  }
  .login {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: rgba(168, 168, 168, 0.2);
    .Mask {
      padding: 35px 55px 35px 35px;
      width: 400px;
      height: 500px;
      margin: 60px auto;
      background: #fff;
      opacity: 1;
      .register-btn {
        width: 100%;
        margin: 0 auto;
        background-color: #36ca99;
      }
    }
  }
}
</style>
