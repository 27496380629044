import request from '@/utils/request'

//问卷主题列表
export function getansThemeList(params){
  return request({
    url: "/admin/base/ansThemeList",
    method: "get",
    params
})
}
//问卷答题列表
export function getansList(params){
  return request({
    url: "/admin/base/ansList",
    method: "get",
    params
});
}
//题目列表
export function getansTitleList(params){
  return request({
    url:'school/site/ansTitleList',
    method: "get",
    params
  })
}

//提交问卷答案
export function subAnsSave(data){
  return request({
    url:'school/site/ansSave',
    method: "post",
    data
  })
}

//用户登录 pc
export function userPcLogin(data){
  return request({
    url:'school/pc/userLogin',
    method: "post",
    data
  })
}

//用户登录 site
export function userSiteLogin(data){
  return request({
    url:'school/site/userLogin',
    method: "post",
    data
  })
}
//问卷答题统计
export function getansStatistics(params){
  return request({
    url: "/admin/base/ansThemeCount",
    method: "get",
    params
  });
}
//问卷复制
export function getCopyQuestionnaire(params){
  return request({
    url: "/admin/base/ansThemeCopy",
    method: "get",
    params
  });
}